import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home';
import { checkCapabilites } from './authentication';

/**
 * route level code-splitting
 * this generates a separate chunk (about.[hash].js) for this route which is lazy-loaded when the route is visited.
 * component: () => import('..component Path'),
 */

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		component: Home,
		meta: { caps: ['web_login'] },
		children: [
			// Dashboard
			{
				name: 'Dashboard',
				path: '',
				component: () => import(/* webpackChunkName: "dashboard" */ '@/views/partials/CADashboard'),
				meta: { menu: true, order: 0, icon: 'mdi-view-dashboard' },
				children: [
					// Clicking notifications opens the task performance for that notification
					// If a `timestamp` option is provided, that event will be highlighted
					{
						name: 'Task Performance',
						path: 'dashboard/event/:id/:timestamp?',
						component: () => import(/* webpackChunkName: "dashboard" */ '@/views/components/TaskPerformancePopup.vue'),
						props: true,
						meta: { menu: false },
					},
				],
			},
			{
				path: 'employees',
				name: 'Users',
				meta: { caps: ['list#User'], menu: true, order: 7, icon: 'mdi-account' },
				component: () => import(/* webpackChunkName: "users" */ '@/views/partials/Users.vue'),
			},
			{
				path: 'reports',
				name: 'Reports',
				meta: { caps: ['report#Task'], menu: true, order: 2, icon: 'mdi-poll' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/Reports.vue'),
			},
			{
				path: 'reports/daily',
				name: 'Daily',
				meta: { caps: ['report#Task'], menu: true, order: 0, icon: 'mdi-calendar-today' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/DailyReports.vue'),
			},
			{
				path: 'reports/activities',
				name: 'Activities',
				meta: { caps: ['report#Task'], menu: true, order: 1, icon: 'mdi-chart-timeline-variant' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/ActivityReports.vue'),
			},
			{
				path: 'reports/checkinout',
				name: 'Check In/Out',
				meta: { caps: ['report#Task'], menu: true, order: 2, icon: 'mdi-check-all' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/CheckInOutReports.vue'),
			},
			{
				path: 'reports/surveyreports',
				name: 'Survey',
				meta: { caps: ['report#Survey'], menu: true, order: 4, icon: 'mdi-playlist-check' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/SurveyReports.vue'),
			},
			{
				path: 'reports/timerreports',
				name: 'Timer',
				meta: { caps: ['report#Task'], menu: true, order: 3, icon: 'mdi-clock' },
				component: () => import(/* webpackChunkName: "reports" */ '@/views/partials/reportsComponents/TimerReports.vue'),
			},
			// Recordings
			{
				path: 'reports/chat',
				name: 'Chat',
				meta: { caps: ['report#Call'], menu: true, order: 5, icon: 'mdi-forum' },
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/chat/ChatRecordings.vue'),
			},
			{
				path: 'reports/chat/:id',
				name: 'ChatRecording',
				meta: { caps: ['view#Call'] },
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/chat/ChatRecordingScreen.vue'),
			},
			{
				path: 'reports/video',
				name: 'Video',
				meta: { caps: ['report#Call'], menu: true, order: 6, icon: 'mdi-play-box' },
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/video/VideoRecordings.vue'),
			},
			{
				path: 'video/:id',
				name: 'VideoRecording',
				meta: { caps: ['view#Call'] },
				component: () => import(/* webpackChunkName: "operatorPages" */ '@/views/partials/recordings/video/VideoRecordingScreen.vue'),
			},
			{
				path: 'clients',
				name: 'Clients',
				meta: { caps: ['list#Organization', 'list#Security_phrases'], menu: true, order: 3, icon: 'mdi-city' },
				component: () => import(/* webpackChunkName: "clientPages" */ '@/views/partials/Clients.vue'),
			},
			{
				path: 'regions',
				name: 'Regions',
				meta: { caps: ['list#Region'], menu: true, order: 5, icon: 'mdi-bank' },
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/Regions.vue'),
			},
			{
				path: 'locations',
				name: 'Locations',
				meta: { caps: ['list#Location'], menu: true, order: 6, icon: 'mdi-store' },
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/Locations.vue'),
			},
			{
				path: 'task-list',
				name: 'Task Lists',
				meta: { caps: ['view#Task'], menu: true, order: 7, icon: 'mdi-checkbox-multiple-marked' },
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/TaskList.vue'),
			},
			{
				path: 'surveys',
				name: 'Surveys',
				meta: { caps: ['list#Survey'], menu: true, order: 8, icon: 'mdi-playlist-check' },
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/SurveyList.vue'),
			},
			{
				path: 'surveys/:surveyId/:locationId',
				name: 'SurveyListId',
				meta: { caps: ['view#Survey', 'edit#Survey'] }, // TODO: Separate views for these capabilities
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/SurveyList.vue'),
			},
			{
				path: 'schedules',
				name: 'Schedules',
				meta: { caps: ['view#Schedule'], menu: true, order: 9, icon: 'mdi-playlist-check' },
				component: () => import(/* webpackChunkName: "mgmtPages" */ '@/views/partials/Schedules.vue'),
			},
		],
	},
];

const router = new VueRouter({
	mode: process.env.VUE_APP_NAVIGATION_MODE || 'hash',
	routes: routes,
});

router.beforeEach(checkCapabilites);

export default router;
