const filterRanges = [
	{ name: 'Today', value: 'today' },
	{ name: 'Yesterday', value: 'yesterday' },
	{ name: 'This Week', value: 'this_week' },
	{ name: 'Last Week', value: 'last_week' },
	{ name: 'This Month', value: 'this_month' },
	{ name: 'Last Month', value: 'last_month' },
	{ name: 'Custom', value: 'custom' },
];

const AlertDialogTypes = Object.freeze({
	SCHEDULE_DELETE: Symbol('Schedule_-_Delete'),
	SCHEDULE_REVERT: Symbol('Schedule_-_Revert'),
	SELECTING_SCHEDULE_AGAIN: Symbol('Selecting_-_Schedule_-_Again'),
	LEAVING_SCHEDULES_ROUTE: Symbol('Leaving_-_Schedules_-_Route'),
});
export { filterRanges, AlertDialogTypes };
