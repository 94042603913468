import User from '@/lib/models/Client/User';
import { RegionalOfficeError, RolesPrivilegesError, UserError } from '@/lib/Errors';
import $http from '../../../plugins/axios';

export default {
	async get () {
		const body = {
			case: 'GET-ALL-EMPLOYEES',
		};

		const response = await $http.post('/api/client/getallemployees', body);

		if (response.data?.success) {
			const data = response.data.body.personobject || [];
			const users = data.map(u => {
				const user = new User(u);
				return user;
			});
			return users;
		} else {
			throw new UserError('Error retrieving Users');
		}
	},
	// async store (object) {
	// 	object.case = 'ADD-EMPLOYEE';
	// 	object.version = 'new';

	// 	const response = await $http.post('/api/client/manageemployees', object);

	// 	if (response.data?.success) {
	// 		const res = {
	// 			body: response.data.body,
	// 			newUser: new User(response.data.body.personobject),
	// 		};
	// 		return res;
	// 	} else {
	// 		throw new UserError(response.data.description);
	// 	}
	// },
	// async update (object) {
	// 	object.case = 'UPDATE-EMPLOYEE';
	// 	object.version = 'new';

	// 	const response = await $http.post('/api/client/manageemployees', object);

	// 	if (response.data?.success) {
	// 		return response.data.body;
	// 	} else {
	// 		throw new UserError('Error updating employee');
	// 	}
	// },
	async getActivityLogs (userId) {
		return $http.get(`/api/reports/activityLogs/${userId}`)
			.then(response => {
				if (response.status === 200) {
					return response.data;
				} else {
					throw new Error('Error fetching activity logs');
				}
			});
	},
	async getEmployeeById (id) {
		const body = {
			employeeId: `${id}`,
			case: 'GET-EMPLOYEE-BY-ID',
		};
		const response = await $http.post('/api/client/getemployee', body);

		if (response.data?.success) {
			const data = response.data.body.personobject;
			const user = new User(data);
			return user;
		} else {
			throw new UserError('Error retrieving User');
		}
	},
	async getSelectedUserDetails (id) {
		const response = await $http.get(`/api/users/${id}/getUserLocationNotificationSettings`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new UserError('Error updating user properties');
		}
	},

	async getRolesPrivileges (id) {
		const response = await $http.get('/api/users/accessedRolesPrivileges', { params: { id } });
		if (response.status === 200) {
			return response.data;
		}
		throw new RolesPrivilegesError('Error fetching roles and privileges.');
	},

	async getUserRoles (item) {
		const response = await $http.get(`/api/users/roles?filter[organization_id]=${item.id}&for_organization=${item.forOrganization}&for_region=${item.forRegion}&for_location=${item.forLocation}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new UserError('Error updating user properties');
		}
	},
	async getLatestAppVersion () {
		const response = await $http.get('/api/products');
		if (response.status === 200) {
			return response.data;
		} else {
			throw new UserError('Error loading App Versions');
		}
	},
	async mapUserDetails (id) {
		const response = await $http.get(`/api/mapUsers/${id}`);
		if (response.status === 200) {
			return response.data;
		} else {
			throw new UserError('Error updating user properties');
		}
	},
	async getEmployeeByEmail (object) {
		object.case = 'GET-EMPLOYEES-FOR-CONSOLE';

		const response = await $http.post('/api/client/employeebyemail', object);

		if (response.data?.success) {
			const data = response.data.body.personobject;
			const user = new User(data);
			return user;
		} else {
			throw new UserError('Error retrieving User');
		}
	},

	async manageUserProperties (body) {
		const response = await $http.post('/api/client/manageuserproperties', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error updating user properties');
		}
	},

	async updateDndNotifications (dndObj) {
		const body = {
			case: 'UPDATE-DND-NOTIFICATIONS',
			settingsObject: JSON.stringify(dndObj),
		};

		const response = await $http.post('/api/client/updateDndNotifications', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error updating user DnD preferences');
		}
	},

	async updateUserProfile (body) {
		body.case = 'UPDATE-PROFILE';

		const response = await $http.post('/api/client/manageuserproperties', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error updating user profile');
		}
	},

	async confirmPassword (body) {
		body.case = 'FORGOT-PASSWORD-LINK-VALIDATION-NEWPASSWORD';

		const response = await $http.post('/api/client/confirmpassword', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error on confirm new password');
		}
	},

	async validateLink (body) {
		body.case = 'FORGOT-PASSWORD-LINK-VALIDATION';
		const response = await $http.post('/api/client/validateLink', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error on verifying link');
		}
	},

	async getShowPin (token) {
		const response = await $http.post('/api/client/validatetokenforpin', { token: token });

		if (response.data?.success) {
			return response.data;
		} else if (response.status === 400) {
			throw new UserError(response.data?.description || 'Invalid activation link or link already used.');
		} else {
			throw new UserError('Invalid activation link or link already used.');
		}
	},

	async confirmValidAndSetPassword (body) {
		const response = await $http.post('/api/client/validateAndConfirm', body);

		if (response.data?.success) {
			return response.data;
		} else {
			throw new UserError('Error on confirm validation and setting the new password');
		}
	},

	// API V2
	async store (body) {
		const response = await $http.post('/api/users', body);
		if (response.status === 201) {
			return response.data;
		} else {
			if (response.response.status === 422 || response.response.status === 500) {
				return response.response.data;
			}
			throw new UserError('Error getting branch structure');
		}
	},
	async updatePassword (object) {
		const response = await $http.patch(`/api/users/changePassword/${object.user_id}`, object);

		if (response.status === 204) {
			return response.data;
		} else {
			return response.response.data;
		}
	},
	async update (object) {
		const response = await $http.patch(`/api/users/${object.id}`, object);

		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422 || response.response.status === 500) {
				return response.response.data;
			}
			throw new UserError('Error updating user');
		}
	},
	async setActiveUser (object) {
		const response = await $http.patch(`/api/users/${object}/restore`);
		if (response.status === 200) {
			return response.data;
		} else {
			if (response.response.status === 422) {
				return response.response.data;
			}
			throw new RegionalOfficeError('Error updating location');
		}
	},
	async delete (object) {
		const response = await $http.delete(`/api/users/${object.id}`, object);
		if (response.status !== 204) {
			throw new UserError('Error updating user');
		}
	},
	// async clearUserAlarm (id) {
	// 	const response = await $http.post(`/api/users/clearAlarm/${id}`, id);
	// 	if (response.status !== 204) {
	// 		throw new UserError('Error clearing alarm of the user');
	// 	}
	// },
};
